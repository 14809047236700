import { Card, Typography } from "antd";
import React, { useMemo } from "react";
import { useMoralis } from "react-moralis";

const { Text } = Typography;

const styles = {
  title: {
    fontSize: "20px",
    fontWeight: "700",
  },
  text: {
    fontSize: "16px",
  },
  card: {
    boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
    border: "1px solid #e7eaf3",
    borderRadius: "0.5rem",
  },
  timeline: {
    marginBottom: "-45px",
  },
};

export default function QuickStart({ isServerInfo }) {


  return (
    <div style={{ display: "flex", gap: "10px" }}>
      
      <div>
        <Card
          style={styles.card}
          title={
            <>
              <Text>Welcome to ChainSwap: Your Entry into the Decentralized World  </Text>
            </>
          }
        >
         <div>We are a multifunctional Web 3.0 platform for all things Crypto </div><br></br>
         <div>Whatever your needs we are here to help.</div><br></br>
         <div>
           <div>Things you can do here:</div><ul type="circle">
  <li>Currency Swaps</li>
  <li>Wallet Transfers</li>
  <li>NFTs</li>
  <li>Check Balance</li>
  <li>Check Balance</li>
</ul>
         </div>
        </Card>
        
      </div>
    </div>
  );
}
